<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteValue($event, null, true)"
    />

    <vs-popup
      title="تفاصيل الخصائص"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{ handleSubmit }"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col w-full">
              <ValidationProvider
                name="قيمة الخاصية"
                rules="required"
                v-slot="{ errors }"
              >
                <label>قيمة الخاصية</label>
                <vs-input
                  placeholder="قيمة الخاصية"
                  v-model="variantValue.value"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
    <vs-popup
      title="تفاصيل الخصائص"
      :active.sync="popupActiveDetails"
    >
      <form>
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/4 w-full">
            <h4>قيمة الخاصية :</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ variantValue.value }}</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <h4>معرّف الخاصية :</h4>
          </div>
          <div class="vx-col sm:w-1/4 w-full mb-2">
            <h4>{{ variantValue.variant_key_id }}</h4>
          </div>
        </div>

        <div class="vx-row mt-5">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              ycolor="success"
              type="filled"
              @click="popupActiveDetails = false"
            >
              حسناً
            </vs-button>
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from "../../../shared/shared-components/ag-grid/AgTable.vue";
import AgTableBtnCell from "../../../shared/shared-components/ag-grid/AgTableBtnCell";
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import utilities from "../../../shared/utilities";

const variantValueRepo = RepositoryFactory.get("variantValue");

export default {
  name: "VariantValue",
  components: {
    AgTable
  },
  data() {
    return {
      gridOptions: null,
      rowData: [],
      variantValue: {
        value: ""
      },
      popupActive: false,
      popupActiveDetails: false,
      isLoading: true,
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: "المعرف",
          field: "id",
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: "القيمة",
          field: "value",
          filter: 'agTextColumnFilter',
        },
        {
          headerName: "الإجراءات",
          field: "id",
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteValue(id, rowIndex);
            },
            editRecord: function (variantValue) {
              self.editValue(variantValue);
            },
            actions: ["edit", "delete"],
          },
        },
      ];
    },

    deleteValue(ids, index, isMultiple = false) {
      console.log(index);
      this.isLoading = true;
      if (isMultiple) ids = ids.join(",");
      variantValueRepo.deleteVariant(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.getAllVariant();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },

    createRecord() {
      utilities.initObjectMembers(this.variantValue);
      this.openPopup();
    },

    editValue(variantValue) {
      let data = {
        id: variantValue.id,
        value: variantValue.value
      };
      Object.assign(this.variantValue, data);
      this.openPopup();
    },

    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },

    onSubmit() {
      this.isLoading = true;
      this.variantValue.variant_key_id = this.$route.params.id;
      if (this.variantValue.id) {
        this.updateVariant(this.variantValue);
      } else {
        this.storeVariant(this.variantValue);
      }
    },

    storeVariant(variantValue) {
      variantValueRepo.storeVariant(variantValue).then((response) => {
        this.rowData.unshift(response.data);
        this.handleResponse();
      });
    },

    updateVariant(variantValue) {
      variantValueRepo.updateVariant(variantValue).then((response) => {
        let index = this.rowData.findIndex((variantValue) => variantValue.id === response.data.id);
        this.rowData[index] = response.data;
        this.handleResponse();
        this.rerender();
      });
    },

    handleResponse() {
      this.isLoading = false;
      this.popupActive = false;
    },

    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },

    getAllVariant() {
      variantValueRepo.get(this.$route.params.id).then((response) => {
        this.rowData = response.data;
        this.isLoading = false;
      });
    }
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.getAllVariant();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
